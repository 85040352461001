<template>
  <v-form ref="form" lazy-validation @submit.prevent="onSubmit" class="app-form mt-4">
    <h4 class="app-stepper-title">Digite a placa de um veículo para continuar</h4>

    <v-row no-gutters>
      <v-col md="12" sm="12" xs="12">
        <v-text-field
          label="Placa*"
          placeholder="Digite a placa do veículo que deseja incluir"
          prepend-inner-icon="directions_bus"
          outlined
          clearable
          color="success"
          v-model="veiculoForm.placa"
          :rules="[rules.required]"
          v-mask="'AAA#X##'"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-card-actions class="ml-0 mr-0">
      <v-spacer></v-spacer>
      <v-btn color="success" depressed type="submit">Continuar</v-btn>
    </v-card-actions>
    <section class="loading" v-if="form.isLoading">
      <v-progress-circular indeterminate color="success"></v-progress-circular>
      <span>Enviando informações...</span>
    </section>
  </v-form>
</template>

<script>
import { mapState, mapActions } from "vuex";
import * as _ from "lodash";

import VeiculosService from "@/services/veiculos.service";
import TipoPedidoService from "@/services/tipo-pedidos.service";
import { TipoPedido } from "@/models/tipo.enum";

export default {
  name: "EnvioDocumentacaoSeguroVeiculoForm",
  data: () => ({
    veiculoForm: {
      placa: null
    },
    veiculos: null,
    rules: {
      required: value => !!value || "Esse campo deve ser preenchido"
    }
  }),
  methods: {
    onSubmit() {
      const isValid = this.$refs.form.validate();

      if (isValid) {
        this.createEnvioDocumentacaoSeguro(this.veiculoForm);
      }
    },
    clearVeiculoForm() {
      this.veiculoForm = {
        placa: null
      };
    },
    loadCurrentSolicitationType() {
      this.searchVeiculosForm.isLoading = true;
      const query = {
        input: {
          key: "ENVIO_DOCUMENTACAO_SEGURO"
        },
        inputPage: {
          page: 1,
          size: 1,
          order: "id",
          descendingOrder: true
        }
      };
      TipoPedidoService.page(query).subscribe(
        ({ items }) => {
          this.searchVeiculosForm.isLoading = false;
          if (items.length) {
            this.veiculoForm.typeSolicitation = items[0].id;
          }
        },
        err => {
          this.searchVeiculosForm.isLoading = false;
        }
      );
    },
    ...mapActions("envioDocumentacaoSeguro", {
      createEnvioDocumentacaoSeguro: "createEnvioDocumentacaoSeguro"
    })
  },
  computed: {
    ...mapState("envioDocumentacaoSeguro", ["form"])
  },
  watch: {
    // verifica se muda o step para limpar os campos do form
    "form.currentStep": function(newValue, oldValue) {
      if (newValue) {
        this.clearVeiculoForm();
      }
    }
  },
  mounted() {
    //this.loadCurrentSolicitationType();
  }
};
</script>

<style lang="scss">
.veiculos-list {
  padding: 0px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  list-style: none;
  flex-wrap: wrap;
  flex-direction: row;

  &__item {
    padding: 0px 5px;
    flex: 1;
    cursor: pointer;
    width: 100%;
    margin-bottom: 15px;
  }
  &__content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 15px;
    border: solid 1px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    color: #999;
    cursor: pointer;

    &:hover,
    &--active {
      background: #067b26;
      color: #fff;
      .veiculos-list__icon {
        color: #fff;
      }
    }
  }

  &__placa {
    text-align: center;
    font-weight: bold;
  }
}
.notfound {
  text-align: center;
  margin: 0px;
}
</style>
